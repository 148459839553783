import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";

function Intro() {
  const [introMd, setIntroMd] = useState('');

  useEffect(() => {
    fetch('markdown/Intro.md')
    .then(response => response.text())
    .then(text => setIntroMd(text));
    }, []);

  const components = {
    code({ className, children }) {
      const match = /language-(\w+)/.exec(className || "");
      return match ? (
        // 코드 (```)
        <SyntaxHighlighter
          style={nord}
          language={match[1]}
          PreTag="div"
        >
          {String(children)
            .replace(/\n$/, "")
            .replace(/\n&nbsp;\n/g, "")
            .replace(/\n&nbsp\n/g, "")}
        </SyntaxHighlighter>
      ) : (
        <SyntaxHighlighter
          style={nord}
          background="green"
          language="textile"
          PreTag="div"
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      );
    },
    // 인용문 (>)
    blockquote({ children, ...props }) {
      return (
        <blockquote
          style={{
            background: "#e9ecef",
            padding: "1px 15px",
            borderRadius: "10px",
          }}
          {...props}
        >
          {children}
        </blockquote>
      );
    },
    img({ ...props }) {
      return (
        <img
          style={{ maxWidth: "40vw" }}
          src={props.src?.replace("../../../../public/", "/")}
          alt="MarkdownRenderer__Image"
        />
      );
    },
    em({ children, ...props }) {
      return (
        <span style={{ fontStyle: "italic" }} {...props}>
          {children}
        </span>
      );
    },
  };

  return (
    <div>
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>{introMd}</ReactMarkdown>
    </div>
  );
}

export default Intro;
