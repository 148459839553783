import './App.css';
import React, { useState } from 'react';
import Intro from './component/Intro';
import Profile from './component/Profile';
import Skill from './component/Skill';
import Project from './component/Project';
function App() {

  const [showIntro, setShowIntro] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [showSkill, setShowSkill] = useState(false);
  const [showProject, setShowProject] = useState(false);
  
  const btnOnclick = (num) => {
    setShowIntro( num===1 ? true : false );
    setShowProfile( num===2 ? true : false );
    setShowSkill( num===3 ? true : false );
    setShowProject( num===4 ? true : false );
  };

  return (
  <div className="App">
    <div id="header">
      <div id="headerContainer">
        <div id="titleContainer">
          <div id="titleComponent">
            <img id="titleImage" alt="Owner avatar" src="https://avatars.githubusercontent.com/u/45790156?s=48&v=4"></img>
            <strong id="titleStrong">
              <a id="title" href="https://log.zziny.com/">
                Park's Log
              </a>
            </strong>
            <span id="titlePublic">
              Public
            </span>
          </div>
        </div>
        <div id="detailsContainer">
          <ul id="detailsUl">
            <li className="detailsLi">
              <a href="#Watch">
              <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true">
                <path d="M8 2c1.981 0 3.671.992 4.933 2.078 1.27 1.091 2.187 2.345 2.637 3.023a1.62 1.62 0 0 1 0 1.798c-.45.678-1.367 1.932-2.637 3.023C11.67 13.008 9.981 14 8 14c-1.981 0-3.671-.992-4.933-2.078C1.797 10.83.88 9.576.43 8.898a1.62 1.62 0 0 1 0-1.798c.45-.677 1.367-1.931 2.637-3.022C4.33 2.992 6.019 2 8 2ZM1.679 7.932a.12.12 0 0 0 0 .136c.411.622 1.241 1.75 2.366 2.717C5.176 11.758 6.527 12.5 8 12.5c1.473 0 2.825-.742 3.955-1.715 1.124-.967 1.954-2.096 2.366-2.717a.12.12 0 0 0 0-.136c-.412-.621-1.242-1.75-2.366-2.717C10.824 4.242 9.473 3.5 8 3.5c-1.473 0-2.825.742-3.955 1.715-1.124.967-1.954 2.096-2.366 2.717ZM8 10a2 2 0 1 1-.001-3.999A2 2 0 0 1 8 10Z"></path>
              </svg>
                Watch
              </a>
              </li>
            <li className="detailsLi">
              <a href="#Fork">
              <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true">
                <path d="M5 5.372v.878c0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75v-.878a2.25 2.25 0 1 1 1.5 0v.878a2.25 2.25 0 0 1-2.25 2.25h-1.5v2.128a2.251 2.251 0 1 1-1.5 0V8.5h-1.5A2.25 2.25 0 0 1 3.5 6.25v-.878a2.25 2.25 0 1 1 1.5 0ZM5 3.25a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Zm6.75.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm-3 8.75a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Z"></path>
              </svg>
                Fork
              </a>
              </li>
            <li className="detailsLi">
              <a href="#Star">
              <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true">
                <path d="M8 .25a.75.75 0 0 1 .673.418l1.882 3.815 4.21.612a.75.75 0 0 1 .416 1.279l-3.046 2.97.719 4.192a.751.751 0 0 1-1.088.791L8 12.347l-3.766 1.98a.75.75 0 0 1-1.088-.79l.72-4.194L.818 6.374a.75.75 0 0 1 .416-1.28l4.21-.611L7.327.668A.75.75 0 0 1 8 .25Zm0 2.445L6.615 5.5a.75.75 0 0 1-.564.41l-3.097.45 2.24 2.184a.75.75 0 0 1 .216.664l-.528 3.084 2.769-1.456a.75.75 0 0 1 .698 0l2.77 1.456-.53-3.084a.75.75 0 0 1 .216-.664l2.24-2.183-3.096-.45a.75.75 0 0 1-.564-.41L8 2.694Z"></path>
              </svg>
                Star
              </a>
              </li>
          </ul>
        </div>
      </div>
      <hr id="headerHr"></hr>
    </div>

    <div id="content">
      <div id="contentGrid">
        <div id="mainLayout">
          <div id="mainBox">
            <div id="mainHeader">
              <div id="mainHeaderLeft">
                <a href="#prd">
                <svg aria-hidden="true" focusable="false" role="img" class="octicon octicon-git-branch" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" ><path d="M9.5 3.25a2.25 2.25 0 1 1 3 2.122V6A2.5 2.5 0 0 1 10 8.5H6a1 1 0 0 0-1 1v1.128a2.251 2.251 0 1 1-1.5 0V5.372a2.25 2.25 0 1 1 1.5 0v1.836A2.493 2.493 0 0 1 6 7h4a1 1 0 0 0 1-1v-.628A2.25 2.25 0 0 1 9.5 3.25Zm-6 0a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0Zm8.25-.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM4.25 12a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"></path></svg>
                  prd
                </a>
              </div>
              <div id="mainHeaderRight">
                <a href="https://github.com/caffinism/park-log">
                  <svg aria-hidden="true" focusable="false" role="img" class="octicon octicon-code" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" ><path d="m11.28 3.22 4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L13.94 8l-3.72-3.72a.749.749 0 0 1 .326-1.275.749.749 0 0 1 .734.215Zm-6.56 0a.751.751 0 0 1 1.042.018.751.751 0 0 1 .018 1.042L2.06 8l3.72 3.72a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L.47 8.53a.75.75 0 0 1 0-1.06Z"></path></svg>
                  Code
                </a>
              </div>
            </div>
            <div id="mainBody">

              <div id="file">

                <table id="mainTable">
                  <tbody id="mainTbody">
                    <tr id="mainHeadTr">
                      <td colspan="3">
                        <img alt="author" size="20" src="https://github.githubassets.com/images/gravatars/gravatar-user-420.png?size=40" data-testid="github-avatar" aria-label="author" height="20" width="20" class="Avatar__StyledAvatar-sc-2lv0r8-0 kYMvPL"></img>
                        <span>park</span>
                        <a href="#Intro" onClick={() => btnOnclick(1)}>add my introduction</a>
                      </td>
                      <td></td>
                    </tr>
                    <tr className="mainTr">
                      <td className="mainTd1" colspan="2">
                        <svg aria-hidden="true" focusable="false" role="img" class="color-fg-muted" viewBox="0 0 16 16" width="16" height="16" fill="currentColor"><path d="M2 1.75C2 .784 2.784 0 3.75 0h6.586c.464 0 .909.184 1.237.513l2.914 2.914c.329.328.513.773.513 1.237v9.586A1.75 1.75 0 0 1 13.25 16h-9.5A1.75 1.75 0 0 1 2 14.25Zm1.75-.25a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h9.5a.25.25 0 0 0 .25-.25V6h-2.75A1.75 1.75 0 0 1 9 4.25V1.5Zm6.75.062V4.25c0 .138.112.25.25.25h2.688l-.011-.013-2.914-2.914-.013-.011Z"></path></svg>
                        <a href="#Intro" onClick={() => btnOnclick(1)}>Intro</a>
                      </td>
                      <td className="mainTd2" colspan="1">add my introduction</td>
                      <td className="mainTd3">yesterday</td>
                    </tr>
                    <tr className="mainTr">
                      <td className="mainTd1" colspan="2">
                        <svg aria-hidden="true" focusable="false" role="img" class="color-fg-muted" viewBox="0 0 16 16" width="16" height="16" fill="currentColor"><path d="M2 1.75C2 .784 2.784 0 3.75 0h6.586c.464 0 .909.184 1.237.513l2.914 2.914c.329.328.513.773.513 1.237v9.586A1.75 1.75 0 0 1 13.25 16h-9.5A1.75 1.75 0 0 1 2 14.25Zm1.75-.25a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h9.5a.25.25 0 0 0 .25-.25V6h-2.75A1.75 1.75 0 0 1 9 4.25V1.5Zm6.75.062V4.25c0 .138.112.25.25.25h2.688l-.011-.013-2.914-2.914-.013-.011Z"></path></svg>
                        <a href="#Profile" onClick={() => btnOnclick(2)}>Profile</a>
                      </td>
                      <td className="mainTd2" colspan="1">add my profile</td>
                      <td className="mainTd3">3 days ago</td>
                    </tr>
                    <tr className="mainTr">
                      <td className="mainTd1" colspan="2">
                        <svg aria-hidden="true" focusable="false" role="img" class="color-fg-muted" viewBox="0 0 16 16" width="16" height="16" fill="currentColor"><path d="M2 1.75C2 .784 2.784 0 3.75 0h6.586c.464 0 .909.184 1.237.513l2.914 2.914c.329.328.513.773.513 1.237v9.586A1.75 1.75 0 0 1 13.25 16h-9.5A1.75 1.75 0 0 1 2 14.25Zm1.75-.25a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h9.5a.25.25 0 0 0 .25-.25V6h-2.75A1.75 1.75 0 0 1 9 4.25V1.5Zm6.75.062V4.25c0 .138.112.25.25.25h2.688l-.011-.013-2.914-2.914-.013-.011Z"></path></svg>
                        <a href="#Skill" onClick={() => btnOnclick(3)}>Skill</a>
                      </td>
                      <td className="mainTd2" colspan="1">add my skill</td>
                      <td className="mainTd3">2 weeks ago</td>
                    </tr>
                    <tr className="mainTr">
                      <td className="mainTd1" colspan="2">
                        <svg aria-hidden="true" focusable="false" role="img" class="color-fg-muted" viewBox="0 0 16 16" width="16" height="16" fill="currentColor"><path d="M2 1.75C2 .784 2.784 0 3.75 0h6.586c.464 0 .909.184 1.237.513l2.914 2.914c.329.328.513.773.513 1.237v9.586A1.75 1.75 0 0 1 13.25 16h-9.5A1.75 1.75 0 0 1 2 14.25Zm1.75-.25a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h9.5a.25.25 0 0 0 .25-.25V6h-2.75A1.75 1.75 0 0 1 9 4.25V1.5Zm6.75.062V4.25c0 .138.112.25.25.25h2.688l-.011-.013-2.914-2.914-.013-.011Z"></path></svg>
                        <a href="#Project" onClick={() => btnOnclick(4)}>Project</a>
                      </td>
                      <td className="mainTd2" colspan="1">add my project</td>
                      <td className="mainTd3">last month</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div id="markdown">
                <div className="markdown">
                  {showIntro && <Intro></Intro>}
                  {showProfile && <Profile></Profile>}
                  {showSkill && <Skill></Skill>}
                  {showProject && <Project></Project>}
                </div>
              </div>

            </div>            
          </div>
        </div>
        <div id="sidebarLayout">
          <div id="sidebarBox">
            <div id="right">
              <div className="rightBox">
                <b>About</b>
                <br/>
                park's log is here.
              </div>
              <hr className="sidebarHr"></hr>
              <div className="rightBox">
                <b>Releases</b>
                <br/>
                No releases published
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  );
}

export default App;
